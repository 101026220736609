
const children = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/views/dashboard'),
    meta: { title: '首页' },
  },
  {
    path: '/curriculum',
    name: 'Curriculum',
    component: () => import('@/views/curriculum'),
    meta: { title: '课程管理' },
  },
  {
    path: '/consumer',
    name: 'Consumer',
    component: () => import('@/views/consumer'),
    meta: { title: '用户管理' },
  },
  {
    path: '/advertisement',
    name: 'Advertisement',
    component: () => import('@/views/advertisement'),
    meta: { title: '广告管理' },
  },
  {
    path: '/classification',
    name: 'Classification',
    component: () => import('@/views/classification'),
    meta: {parentTitle: '系统管理', title: '课程分类' },
  },
  {
    path: '/administrators',
    name: 'Administrators',
    component: () => import('@/views/administrators'),
    meta: {parentTitle: '系统管理', title: '管理人员' },
  }
]
const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/layouts/index'),
    children: children
  },

  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/login'),
  },

]
export default routes;