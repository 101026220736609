/**
 * 数据字典
 */

import { nextTick, reactive } from 'vue'

const ddo = {
  // state存储公共数据
  state: {
    // 用户信息
    userInfo: {
      imageUrl: '',
      fullName: '',
      only: ''
    },
    // 侧边栏菜单
    menus: [

    ],
    redirect: {
      selectedKeys: [],
      openKeys: [],
      redirectUrl: ''
    }

  },
  setMenu(info) {
    this.state.menus = info
  },
  getMenu() {
    return this.state.menus

  },
  getUserInfo() {
    return this.state.userInfo
  },
  setUserInfo(info) {
    this.state.userInfo = info;
  },
  setRedirect(data) {
    this.state.redirect = data;
  },
  getRedirect() {
    return this.state.redirect

  },
  removeUserInfo() {
    for (var i in this.state.userInfo) {
      this.state.userInfo[i] = ''
    }
    localStorage.removeItem("store")
  },
  // 设置头像
  setAvatar(url) {
    this.state.userInfo.avatar = url;
  },
  //  本地保存state中的数据
  saveState() {
    if (localStorage.getItem("store")) {
      this.state = Object.assign({}, this.state, JSON.parse(localStorage.getItem("store")))
    }
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("store", JSON.stringify(this.state))
    })
  },
  // 当前页面刷新
  // =======================
  isRouterAlive: true,
  reload() {
    console.log("执行", this)
    this.isRouterAlive = false
    nextTick(() => {
      setTimeout(() => {
        this.isRouterAlive = true
      }, 300)
    })


  },
  // ========================
  // ddo初始化
  init() {
    this.saveState()
  }

};

ddo.init();

export default reactive(ddo);
