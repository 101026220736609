import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import store from "@/store";
import 'ant-design-vue/dist/antd.less';
import '@/index.less';


// import constants from '@/common/constants'
//导入已封装好的路由管理器
import router from '@/common/router'

//导入已封装好的服务端访问对象
// import request from "@/common/request.js"

//导入防抖函数
// import debounce from "@/common/debounce"
//导入快捷提示对象
// import tip from "@/common/tip"

//数据字典初始化
import "@/common/ddo.js"

import directives from "@/directives";

// import '@/components/public.css'
const app = createApp(App);
app.use(router).use(Antd).use(directives, { router, store }).mount('#app')
