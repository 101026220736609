import { createRouter, createWebHashHistory } from 'vue-router'
import routes from './routes';
import { getLocalToken } from '../token.js'
import {
  BASE_URL, LOGIN_ROUTE_NAME
} from '../constants'
import ddo from '@/common/ddo.js'//数据字典对象

const router = createRouter({
  history: createWebHashHistory(BASE_URL),
  routes
});


//白名单
// const whiteList = [LOGIN_ROUTE_NAME];
// router.beforeEach(async (to, from) => {
//   // return true;
//   if(to.path=='/'){
//     return { path: ddo.getRedirect.redirectUrl };
//   }
//   if (whiteList.find(routeName => routeName === to.name)) {
//     return true;
//   } else if (getLocalToken()) {
//     return true;
//   } else {
//     return { name: LOGIN_ROUTE_NAME, replace: true };
//   }

// })
const whiteList = [LOGIN_ROUTE_NAME];
router.beforeEach(async (to, from,next) => {
  
  if (whiteList.find(routeName => routeName === to.name)) {
   next()
  } else if (getLocalToken()) {
    next()
  } else {
  next({ name: LOGIN_ROUTE_NAME, replace: true })
  }

})
export default router;