<template>
  <a-config-provider :locale="zhCN">
    <div id="app">
      <router-view></router-view>
      <div v-show="sureLoading.loading" class="sure-loading">
        <a-spin />
      </div>
    </div>
  </a-config-provider>

</template>

<script>
  import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
  import moment from 'moment';
  import 'moment/locale/zh-cn';
  moment.locale('zh-cn');
  import sureLoading from "@/common/sureLoading.js"

  export default {

    setup() {
      return {
        sureLoading,
        zhCN,
        moment
      }

    }


  }
</script>


<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: left;
    color: #2c3e50;
    height: 100%;

  }

  .sure-loading {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    background: rgba(255, 255, 255, 0.5);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    z-index: 10000;
  }
</style>